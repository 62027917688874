






































import {Component, Prop, Vue} from "vue-property-decorator";
import EmployeesHRDocumentPayload from "@/dto/archive/EmployeesHRDocumentsPayload";
import DocumentUtils from "@/utils/DocumentUtils";
import EmployeeService from "@/services/EmployeeService";
import {processError} from "@/utils/ComponentUtils";
import {namespace} from "vuex-class";
import AddEmployeeDocumentModal from "@/components/payroll/business/employments/employee/AddEmployeeDocumentModal.vue";
import {EmploymentVue} from "@/components/payroll/business/employments/EmploymentVue";
import {IEmployment} from "@/dto/payroll/IEmployment";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import {IContractor} from "@/components/payroll/business/employments/contractors/Contractor";
import {IndividualForeignContractorDTO} from "@/components/payroll/business/employments/foreignContractors/ForeignContractorPayloads";
import IndividualForeignContractorService from "@/components/payroll/business/employments/foreignContractors/IndividualForeignContractorService";
import ContractorDocumentDTO from "@/dto/contractor/ContractorDocumentDTO";
import AddContractorDocumentModal from "@/components/payroll/business/employments/contractors/AddContractorDocumentModal.vue";

const AppModule = namespace("App");

@Component
export default class ContractorDocuments extends EmploymentVue<IndividualForeignContractorDTO> {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  employment!: IndividualForeignContractorDTO

  private contractorDocuments: Array<ContractorDocumentDTO> = [];

  mounted(): void {
    this.loadDocuments();
  }

  loadDocuments(): void {
    this.startLoading();
    IndividualForeignContractorService.getDocuments(this.employment.id!).then(
        response => {
          this.contractorDocuments = response.data;
          this.stopLoading();
        },
        error => {
          processError(error, this);
          this.stopLoading();
        }
    );
  }



  showModal(): void {
    this.$modal.show(
        AddContractorDocumentModal,
        {onSuccess: this.loadDocuments}
    );
  }

}

