






















































import {Component, Prop, Vue} from "vue-property-decorator";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {ifValid, processError} from "@/utils/ComponentUtils";
import {namespace} from "vuex-class";
import SelectOption from "@/components/common/SelectOption";
import AddContractorDocumentPayload from "@/dto/contractor/AddContractorDocumentPayload";
import IndividualForeignContractorService from "@/components/payroll/business/employments/foreignContractors/IndividualForeignContractorService";
import {ContractorDocumentType} from "@/constants/ContractorConstants";

const AppModule = namespace("App");

@Component({
  components: {
    SingleFileHolder,
    PortalDate,
    PortalSelect,
    PortalInput
  }
})
export default class AddContractorDocumentModal extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  private onSuccess!: () => void

  private payload = new AddContractorDocumentPayload();
  private isValidFile = true;

  addContractorDocument(): void {
    this.isValidFile = !!this.payload.file;
    ifValid(this, () => {
      if (this.isValidFile) {
        this.startLoading();
        IndividualForeignContractorService.addDocument(this.contractorId, this.payload).then(
            () => {
              this.stopLoading();
              this.$modal.hideAll();
              this.onSuccess();
            },
            error => {
              processError(error, this);
              this.stopLoading();
            }
        );
      }
    });
  }

  buildTypes(): Array<SelectOption> {
    return Object.entries(ContractorDocumentType)
        .map(type => SelectOption.builder().title(type[1]).value(type[0]).build());
  }

  get contractorId(): number {
    return Number.parseInt(this.$route.params.contractorId);
  }

}
