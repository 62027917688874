import FileMetaDTO from "@/dto/files/FileMetaDTO";
import { EmployeesHRDocumentType } from "@/constants/DocumentConstants";
import {ContractorDocumentType} from "@/constants/ContractorConstants";

export default class AddContractorDocumentPayload {

    type: ContractorDocumentType | null = null;
    year?: number | null = null;
    state?: string | null = null;
    country?: string | null = null;
    signDate: Date | null = null;
    file: FileMetaDTO | null = null;

}
